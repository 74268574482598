.text-belt-container {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, #150424, #09052e, #200423);
  padding-bottom: 0;
  border: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
  transform: translateZ(100px);
  border-bottom: 2px solid #ed6ed1;
}

.text-belt-wrapper {
  position: relative;
  height: 2.5rem;
  padding-top: 10px;
  padding-bottom: 20px;
  margin: 0;
  overflow: hidden;
}

.text-belt {
  position: absolute;
  display: flex;
  left: 0;
  gap: 1.5rem;
  will-change: transform;
  padding: 0;
}

.text-item {
  flex-shrink: 0;
  border-radius: 0.2rem;
  overflow: hidden;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(78, 20, 91);
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.5);
}

.belt-text {
  width: auto;
  object-fit: cover;
  display: block;
  padding: 0;
}
