html,
body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

.hero-section {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 0;
}

.parallax-background {
  position: absolute;
  inset: 0;
  z-index: 0;
  background-image: url("/parallax1.png");
  background-position: center;
  background-size: cover;
  filter: brightness(0.6) blur(4px);
  will-change: transform;
  width: 100%;
}

.parallax-foreground {
  position: absolute;
  inset: 0;
  z-index: 1;
  will-change: transform;
  overflow: hidden;
  width: 100%;
  filter: brightness(1);
}

.parallax-foreground img {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  object-position: bottom;
  transform: scale(1);
}

.hero-content {
  position: relative;
  padding-bottom: 200px;
  z-index: 2;
  text-align: center;
  width: 100%;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.hero-title {
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 16rem;
  background-clip: text;
  color: aliceblue;
  animation: pulsesmall 10s infinite;
  text-shadow: 0 0 10px #ff2975, 0 0 20px #00ddff;
  -webkit-background-clip: text;
}

@keyframes pulsesmall {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #ececec;
}

.cta-button {
  background-color: rgba(92, 154, 225, 0.223);
  color: white;
  font-weight: bold;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.25rem;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
}

.cta-button:hover {
  transform: scale(1.03);
  box-shadow: 0px 2px 5px 0px #28bfff, 0 -2px 5px 0px #f694ff,
    0 0px 10px 0px #a201ff;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .hero-title {
    font-size: 3.5rem;
    margin-top: 12rem;
  }

  .hero-content {
    padding-bottom: 150px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
    margin-top: 10rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .cta-button {
    padding: 0.75rem 1.75rem;
    font-size: 1rem;
  }

  .hero-content {
    padding-bottom: 100px;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
    margin-top: 8rem;
  }

  .hero-subtitle {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 0.6rem 1.4rem;
    font-size: 0.9rem;
  }

  .hero-content {
    padding-bottom: 70px;
  }
}
