.image-belt-container {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, #380334, #2b0e4d, #120634);
  padding-bottom: 0;
}

.image-belt-wrapper {
  position: relative;
  height: 7rem;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  overflow: hidden;
}

.grid-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(241, 0, 177, 0.2) 1px, transparent 1px),
    linear-gradient(90deg, rgba(241, 0, 177, 0.2) 1px, transparent 1px);
  background-size: 4rem 4rem;
  background-position: center;
}

.image-belt {
  position: absolute;
  display: flex;
  left: 0;
  gap: 1rem;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.image-item {
  flex-shrink: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.belt-image {
  height: 7rem;
  width: auto;
  object-fit: cover;
  display: block;
  padding: 0;
}
